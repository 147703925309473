import {styled} from '../stitches.config'

const Banner = () => {
    return(
        <>
            <BannerContainer>
                <BannerLogo src="logos/standard_bank.png" alt="logo"/>
                <BannerLogo src="logos/netcare_digital.svg" alt="logo"/>
            </BannerContainer>
            <TitleDiv>
                <b>Care@Work</b>: Employee Lookup Portal
            </TitleDiv>
        </>

    )

}
export default Banner;

const BannerContainer = styled( 'div' ,{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 50px',
        background: 'transparent'
    }
)

const BannerLogo = styled('img', {
    height: '60px',
    width: 'auto'
})

const TitleDiv = styled(
    'div', {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.5rem',
        padding: '20px',
        color: '$primary3'
    }
)