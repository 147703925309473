import React, {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useStores} from "../stores";
import {useSnackbar} from "notistack";
import {useStyles} from "./styles/textFieldStyles";

const ForgotPassword = () => {
    const {authStore} = useStores();
    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
    const [processingReset, setProcessingReset] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const closeDialog = ()=>{
        setOpenForgotPasswordDialog(false);
    }

    const openDialog = ()=>{
        setOpenForgotPasswordDialog(true);
    }
    const classes = useStyles();
    return (
        <div style={{display: "flex", width: '100%', justifyContent: 'flex-end'}}>
            <Button
                color="primary"
                onClick={openDialog}
                sx={{
                    '&.MuiButton-root':{
                        color: '#1D3440',
                        '&:hover':{
                            color: '#000d1a',
                            backgroundColor: 'transparent'

                        }
                    },
                }}
            >
                Forgot password?
            </Button>
            <Dialog
                open={openForgotPasswordDialog}
                onClose={closeDialog}
                PaperProps={{
                  component: 'form',
                  onSubmit: (event) => {
                    event.preventDefault();
                    setProcessingReset(true);
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const email = formJson.email;
                    authStore.resetPassword(email)
                        .then(
                            (res)=>{
                                if(authStore.errorMessage){
                                    enqueueSnackbar(authStore.errorMessage, {'variant': 'error'});
                                }
                                else{
                                    enqueueSnackbar("Password reset link sent", {'variant': 'success'})
                                }
                            }
                        ).finally(()=>{
                            setProcessingReset(false);
                            closeDialog();
                        })
                  }
                }}
              >
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter email address to send reset link to
              </DialogContentText>
              <TextField
                className={classes.root}
                autoFocus
                required
                margin="dense"
                id="email"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                  sx={{
                    '&.MuiButton-root':{
                        color: '#1D3440',
                        '&:hover':{
                            backgroundColor: 'transparent'
                        }
                    },
                  }}
                  onClick={closeDialog}
              >
                  Cancel
              </Button>
              <Button
                  type="submit"
                  disabled={processingReset}
                  sx={{
                    '&.MuiButton-root':{
                        color: '#1D3440',
                        '&:hover':{
                            backgroundColor: 'transparent'
                        }
                    },
                  }}
              >
                  Send link
              </Button>
            </DialogActions>
          </Dialog>
        </div>
    )
}

export default ForgotPassword;