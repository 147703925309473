import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const verify_patient_details = async (identification_type, identification_number) =>
  axios
    .post(`${BASE_URL}/StandardBank/verify_patient_details`, {
      identification_type,
      identification_number
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
        if(err?.response?.status === 401){
            throw new Error("Unauthorized");
        }
        else if([400, 503].includes(err?.response?.status)){
            throw new Error(err?.response?.data);
        }
        else{
            throw new Error("Unable to verify user. Please contact support");
        }
    });
