import React, { useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {styled} from "../stitches.config";
import Button from "@mui/material/Button";
import {useStores} from "../stores/index";
import { useSnackbar } from 'notistack';
import ForgotPassword from "../components/ForgotPassword";
import {observer} from 'mobx-react-lite'
import router from "../router";
import TextField from "@mui/material/TextField";
import {useStyles} from "../components/styles/textFieldStyles";
import {useButtonStyles} from "../components/styles/buttonStyles";
import {TermsAndCondtions} from "../components/TermsAndConditions";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');
    const {authStore} = useStores();
    const { enqueueSnackbar } = useSnackbar();

    const handleEmailChange = (value) => {
        setEmail(value);
    };

    const handlePasswordChange = (value) => {
        setPassword(value);
    };


    const login = () => {
        setSubmitting(true);
        setError('');
        authStore.login(email, password)
            .then(
                (res)=>{
                    setSubmitting(false);
                    if(!authStore.errorMessage){
                        router.navigate(
                            {
                                to: '/verify',
                            }
                        ).catch(console.error)
                    }
                    else{
                        enqueueSnackbar(authStore.errorMessage, {'variant': 'error'});
                    }
                }
            )
    };

    return (
        <Root>
            <Container>
                <LinearProgress style={{ visibility: submitting ? 'visible' : 'hidden' }} />
                <ContentContainer>
                        <Email
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <Password
                            value={password}
                            onChange={handlePasswordChange}
                            error={error}
                        />
                        <ForgotPassword/>
                        <Actions
                            disabled={submitting || (password.length===0 || email.length===0)}
                            onLogin={login}
                        />
                        <TermsAndCondtions/>

                </ContentContainer>
            </Container>
        </Root>
    );
};

const Email = ({ value, onChange }) => {
    const classes = useStyles();
    return (
        <Content>
            <Title>Sign in</Title>
            <SubTitle></SubTitle>

            <TextField
                className={classes.root}
                style={{
                    marginTop: '15px'
                }}
                InputLabelProps={{
                    zindex: '1',
                }}
                label="Email"
                variant="outlined"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                autoFocus
            />
        </Content>
    );
};

const Password = ({ value, onChange, error }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <React.Fragment>
            <Content>
                <TextField
                    className={classes.root}
                    style={{
                        marginTop: '15px'
                    }}
                    InputLabelProps={{
                        zindex: '1',
                    }}
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    error={error ? true : false}
                    helperText={error}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    edge="end"
                                    size="large"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    autoFocus
                />
            </Content>
        </React.Fragment>
    );
};


const Actions = ({disabled, onLogin}) => {
    const buttonClasses = useButtonStyles();
    return (
        <ButtonContainer>
            <Button
                style={{width: '100%'}}
                variant="contained"
                color="primary"
                onClick={onLogin}
                disabled={disabled}
                className={buttonClasses.root}
            >
                Login
            </Button>

        </ButtonContainer>
    )
}
const Root = styled(
    'div', {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
)

const Container = styled(
    'div', {
        border: '1px solid #dadce0',
        borderRadius: '8px',
        width: '450px',
    }
)

const ContentContainer = styled(
    'div', {
        height: 'auto',
        minHeight: '400px',
        padding: '48px 40px 36px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: '.2s',
    }
)

const Content = styled(
    'div', {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        color: '$primaryBlack',
        width: '100%',
    }
)

const Title = styled(
    'div', {
        fontSize: '24px',
        marginTop: '$3',
        fontWeight: 400,
        color: '$primary3'
    })


const SubTitle = styled(
    'div', {
        fontSize: '$3',
        fontWeight: 400,
        marginTop: '$2',
        color: '$primaryText'
    })


const ButtonContainer = styled(
    'div', {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '$3',
        padding: '0px'

    })





export default observer(Login);
