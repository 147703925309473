import React from 'react';
import AuthStore from "./auth.store";


export class RootStore {
  constructor() {
    // Bcause of passing this to sub stores, all stores can access RootStore and other stores
    this.authStore = new AuthStore(this);
  }
}

const rootStore = new RootStore();

const StoresContext = React.createContext(rootStore);
export const useStores = () => React.useContext(StoresContext);

export default rootStore;