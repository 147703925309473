import {styled} from "../stitches.config";
import Button from "@mui/material/Button";
import {useButtonStyles} from "./styles/buttonStyles";
const Patient = ({patientDetails, clearPatientDetails}) => {
    const buttonClasses = useButtonStyles();
    const frontendWebsite = process.env.REACT_APP_FRONTEND_BASE_URL;
    const detailFields = [
        {'id': 'first_name', 'label': 'Name'},
        {'id': 'last_name', 'label': 'Surname'},
        {'id': 'contact_number', 'label': 'Contact number'},
        {'id': 'email', 'label': 'Email'},
        {'id': 'gender', 'label': 'Gender'},
    ]

    const displayDetailFields = [
        {'id': 'personnel_number', 'label': 'Personnel number'},
        {'id': 'bank_med_indicator', 'label': 'Bankmed'},
        {'id': 'medical_aid_member_number', 'label': 'Medical aid number'},
        {'id': 'cost_center_code', 'label': 'Cost centre '},
        {'id': 'company_code', 'label': 'Company code'},
        {'id': 'bussiness_segment_desc', 'label': 'Business Segment'},
        {'id': 'bussiness_unit_level_1_desc', 'label': 'Business Unit '},
    ]

    const fullDetailFields = [
        {'id': 'client', 'label': 'Client'},
        {'id': 'identifier_type', 'label': 'Identity Type'},
        {'id': 'identifier', 'label': 'Identifier'},
        ...detailFields,
    ]

    const getCareAtWorkLink = () => {
        const careAtWorkLinkFields = [
            `${frontendWebsite}/manual_booking/create/?`,
            ...fullDetailFields.map(
                (field, index) => {
                    return `${field.id}=${encodeURIComponent(patientDetails[field.id])}&`
                }
            )
        ]
        careAtWorkLinkFields.push(`prepopulation_meta=${encodeURIComponent(JSON.stringify(patientDetails['prepopulation_meta']))}`)
        return careAtWorkLinkFields.join('');
    }
    return(
        <div style={{display:"flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", padding: '25px'}}>
            <DetailsContainer>
                {
                    [...detailFields, ...displayDetailFields].map(
                        (detail, idx) => {
                            return(
                                <Detail key={idx}>
                                    <FieldLabel>
                                        <b>{`${detail.label}:`}</b>
                                    </FieldLabel>
                                    <FieldValue>
                                        {patientDetails[detail.id]}
                                    </FieldValue>
                                </Detail>
                            )
                        }
                    )
                }
            </DetailsContainer>
            <ProceedContainer>
                <Button
                    onClick={clearPatientDetails}
                    variant={'contained'}
                    className={buttonClasses.root}
                >
                    Go back
                </Button>
                <NavigationLink href={getCareAtWorkLink()} target={'_blank'} >
                    Launch on Care@Work
                </NavigationLink>
            </ProceedContainer>
        </div>
    )
}

export default Patient;


const DetailsContainer = styled('div',
    {
        display: 'flex',
        flexDirection: 'column',
        color: 'gray',
        gap: '15px'
    }
)

const Detail = styled('div',
    {
        width: '600px',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '3fr 7fr',
        gridTemplateRows: 'auto',
        borderBottom: '1px solid gray',
        fontSize: '1rem',
        marginBottom: '5px',
        marginTop: '5px'
    }
)

const ProceedContainer = styled('div',
    {
        display: 'flex',
        padding: '50px',
        gap: '15px'
    }
)

const NavigationLink = styled('a',
    {
       borderRadius: '5px',
        backgroundColor: '#1D3440',
        padding: '10px',
        width: 'fit-content',
        fontSize: '0.875rem',
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#000d1a'
        },
    }
)

const FieldLabel = styled('div',
    {
        fontWeight: 'bold',
        display: "flex",
        justifyContent: "flex-start",
        color: `$primaryText`
    }
)

const FieldValue = styled('div',
    {
        display: "flex",
        justifyContent: "flex-start",
        color: `$primaryText`
    }
)