import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import Login from "../pages/Login";

export const Route = createFileRoute('/login')({
  validateSearch: z.object({
    redirect: z.string().catch('/verify'),
  }),
  component: Login,
});
