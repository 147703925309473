import {Form, Field} from 'react-final-form'
import FormRadioGroup from "../components/form/RadioGroup";
import FormTextField from "../components/form/TextField";
import {styled} from "../stitches.config";
import Button from "@mui/material/Button";
import {verify_patient_details} from "../api/verify";
import { useSnackbar } from 'notistack';
import {useState, useEffect} from 'react';
import Patient from "../components/Patient";
import {observer} from 'mobx-react-lite'
import router from "../router";
import {evaluatePolishExpression} from "@a2d24/recycle-frontend-core";
import {useButtonStyles} from "../components/styles/buttonStyles";
import ProfileMenu from "../components/ProfileMenu";
import VerificationBackDrop from "../components/VerificationBackDrop";

const Verification = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [showDetails, setShowDetails] = useState(false);
    const [patientDetails, setPatientDetails] = useState(null);
    const [verifying, setVerifying] = useState(false);
    const buttonClasses = useButtonStyles();
    const states = ['Verifying identifier', 'Searching database', 'Fetching patient details'];
    const [verificationProgressIndex, setVerificationProgressIndex] = useState(-1);
    const isValidSAID = (value) => {
        return evaluatePolishExpression(['valid_said', 'get', 'identifier'], { identifier: value })
            ? undefined
            : 'Invalid South African ID';
    };
    const onSearch = (values) => {
        setVerifying(true);
        return verify_patient_details(
            values?.identification_type, values?.id_number
        ).then(
            (res)=>{
                enqueueSnackbar("Patient verified", {variant: 'success'})
                setPatientDetails(res);
                setVerifying(false);
            }
        ).catch(
            (err)=>{
                setPatientDetails(null);
                if(err.message === "Unauthorized"){
                    router.navigate(
                        {
                            to: '/login',
                        }
                    ).catch(console.error
                    )
                }
                enqueueSnackbar(err.message, {variant: 'error'});
                setVerifying(false);
            }
        )
    }

    useEffect(() => {
        if(!verifying){
            setVerificationProgressIndex(-1);
        }
    }, [verifying]);

    const required = value => (value ? undefined : 'Required')

    const getVerificationProgress = (idx)=>{
        if(idx < 0){
            return "Processing";
        }
        else{
            return states[idx];
        }
    }

    useEffect(
        ()=>{
            if(patientDetails){
                setShowDetails(true);
            }
        }
        ,[patientDetails]
    )

    useEffect(()=>{
        if(verifying){
            const tempIndex = verificationProgressIndex + 1;
            setVerificationProgressIndex(tempIndex);
        }
    }, [verifying])

    useEffect(()=>{
        if(verifying && verificationProgressIndex<states.length -1) {
            setTimeout(() => {
                const tempIndex = verificationProgressIndex + 1;
                setVerificationProgressIndex(tempIndex);
            }, 1500)
        }
    }, [verificationProgressIndex])

    return(
        <>
            <ProfileMenu/>
            <Form
                onSubmit={onSearch}
                validate={values => {
                    const errors = {}
                    if(!values.identification_type){
                        return 'Required';
                    }
                    if(values.identification_type === 'id_number'){
                        errors.id_number=isValidSAID(values.id_number);
                    }
                    else if(values.identification_type === 'passport_number'){
                        errors.id_number=required(values.id_number);
                    }
                    return errors
                  }
            }
                render={
                    ({handleSubmit, form, values, submitting})=> (
                        <form
                            onSubmit={handleSubmit}
                            style={
                                {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    gap: '50px'
                                }
                            }
                        >
                            <FieldsContainer>
                                <Field
                                    name={'identification_type'}
                                    component={FormRadioGroup}
                                    label={'Identity Type'}
                                    options={
                                        [
                                            {'label': 'ID Number', 'value': 'id_number'},
                                            {'label': 'Passport Number', 'value': 'passport_number'},
                                        ]
                                    }
                                    validate={required}
                                    color={'#1D3440'}
                                />
                                <Field
                                    name={'id_number'}
                                    label={values?.identification_type === 'passport_number'?'Passport Number':'ID Number'}
                                    component={FormTextField}
                                    required={true}
                                />
                            </FieldsContainer>
                            {
                                !showDetails?
                                    <Button
                                        type={'submit'}
                                        variant={'contained'}
                                        disabled={submitting}
                                        className={buttonClasses.root}
                                    >
                                        Search
                                    </Button>
                                    :null
                            }
                        </form>
                    )
                }
                initialValues={
                    {
                        'identification_type': "id_number",
                    }
                }
            />
            {
                showDetails === true?
                    <Patient
                        patientDetails={patientDetails}
                        clearPatientDetails={()=>{
                            setPatientDetails(null);
                            setShowDetails(false);
                        }}
                    />
                :
                    null
            }
            <VerificationBackDrop open={verifying} text={getVerificationProgress(verificationProgressIndex)}/>
        </>
    )
}


const FieldsContainer = styled(
    'div', {
        width: '600px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'flex_start',

    })


export default observer(Verification);