import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import Verification from "../pages/Verification";
import rootStore from "../stores";
export const Route = createFileRoute('/verify')({
  beforeLoad: async ({ location }) => {
    if (!rootStore.authStore.accessToken) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: Verification,
});
