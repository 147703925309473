import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const portal_namespace = process.env.REACT_APP_PORTAL_NAMESPACE;
export const authorize = async (token) =>
  axios
    .post(`${BASE_URL}/PortalAuthorization/authorize`, {
      token,
      portal_namespace
    })
    .then((res) => {
        return res.data;
    }).catch((err)=>{
        if([401, 403].includes(err?.response?.status)){
            throw new Error(err?.response?.data);
        }
        else{
            throw new Error("Unable to login. Please contact support")
        }
  });
