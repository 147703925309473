import TextField from "@mui/material/TextField";
import {useStyles} from "../styles/textFieldStyles";

const FormTextField = ({input:{value, onChange}, meta, label, variant='standard'}) => {
    const classes = useStyles()
    return (
        <>
            <TextField
                className={classes.root}
                label={label}
                variant={variant}
                onChange={(event)=>{onChange(event.target.value)}}
            />
            {meta.error && meta.modified && meta.dirty && (
                <label
                    style={{
                        color: '#d29292',
                        fontSize: '12px',
                        display: 'block',
                        fontWeight: 400,
                        marginTop: '3px',
                        textAlign: 'left',
                    }}
                >
                    {meta.error}
                </label>
            )}
        </>
    )
}
export default FormTextField;