import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useState} from 'react';
import Button from "@mui/material/Button";
import {useStores} from "../stores";
import {styled} from "../stitches.config";

const ProfileMenu = () =>{
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = ()=>{
        setAnchorEl(null);
    }
    const {authStore} = useStores();
    const logout = () => {
        authStore.logout();
        handleClose();
    }

    if(authStore.fullName){
        return(
            <>
                <Button
                    onClick={handleClick}
                    sx={{
                        '&:hover': {backgroundColor: 'transparent'},
                        '&:focus': {backgroundColor: 'transparent'},
                        '&:active': {backgroundColor: 'transparent'},
                    }}
                >
                    <ProfileMenuDiv>
                        <AccountCircleIcon sx={{width: '25px'}}/>
                        <div>
                            {authStore.fullName.toUpperCase()}
                        </div>
                        <ArrowDropDownIcon sx={{width: '25px'}}/>
                    </ProfileMenuDiv>
                </Button>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
            </>
        )
    }
    else{
        return null;
    }

}

const ProfileMenuDiv = styled('div', {
        display: 'flex',
        color: '$primary3',
        fontColor: '$primary3',
        fontSize: '0.8rem',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px'
})
export default ProfileMenu;

