import axios from 'axios';


export const login = async (
  username,
  password,
  {
    grant_type = 'http://auth0.com/oauth/grant-type/password-realm',
    domain,
    realm,
    client_id,
    audience,
  },
) =>
  axios
    .post(`https://${domain}/oauth/token`, {
      grant_type,
      audience,
      realm,
      client_id,
      username,
      password,
    })
    .then((res) => {
      return res.data;
    });


export const resetPassword = async (email, { domain, client_id, connection }) =>
    axios.post(`https://${domain}/dbconnections/change_password`, {
        client_id,
        connection,
        email,
    });