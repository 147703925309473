import {makeStyles} from "@mui/styles";

export const useButtonStyles = makeStyles({
  root: {
        '&.MuiButton-contained':{
            backgroundColor: '#1D3440',
            color: 'white',
            '&:hover':{
                backgroundColor: '#000d1a'
            }
        },
    }
});
