import { makeAutoObservable, reaction, runInAction } from 'mobx';
import {login, resetPassword} from '../lib/auth';
import axios, { AxiosError } from 'axios';
import { authorize } from '../api/authorization';
import {jwtDecode} from 'jwt-decode';
import router from "../router";

// TODO: USE CORRECT IMPORTS
const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;

const AUTH_LOCALSTORAGE_CACHE_KEY = 'StandardBank-AuthProviderToken';

class AuthStore {
  requestInFlight = false;
  rootStore;
  errorMessage = null;
  failedLoginAttempts = 0;
  warningMessage = null;
  accessToken = null;
  logoutTimer = null;
  axiosInterceptors = null;
  fullName = null;
  // RootStore is passed in constructor
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
    this.loadAccessToken();
    // window.location.href = `${window.location.origin}/verify`
    reaction(
      () => this.accessToken,
      (accessToken) => {
        if (accessToken) {
          this.setupAutoLogout();

          if (this.axiosInterceptors) {
            axios.interceptors.request.eject(this.axiosInterceptors);
          }

          this.axiosInterceptors = axios.interceptors.request.use(
            (request) => {
              const url = request?.url;

              if (url && url.startsWith(BASE_URL)) {
                request.headers['Authorization'] = `Bearer ${this.accessToken}`;
              }

              return request;
            },
            (error) => {
              return Promise.reject(error);
            },
          );
        }
      },
      {
        fireImmediately: true, // For initial run during construction
      },
    );
  }

  reset() {
    this.requestInFlight = false;
    this.errorMessage = null;
    this.warningMessage = null;
    this.accessToken = null;
    this.fullName = null;

    // Dont reset failedLoginAttempts

    // Eject any previous interceptors
    if (this.axiosInterceptors) {
      axios.interceptors.request.eject(this.axiosInterceptors);
      this.axiosInterceptors = null;
    }
  }

  async login(username, password) {
    this.reset();
    // TODO: What is a realm?
    let realm = 'Username-Password-Authentication';
    this.setRequestInFlight(true);
    let response= null;

    try {
      response = await login(username, password, {
        domain: AUTH_DOMAIN,
        client_id: AUTH_CLIENT_ID,
        audience: AUTH_AUDIENCE,
        realm,
      });
    } catch (err) {
      this.setRequestInFlight(false);

      if (err instanceof AxiosError) {
        const errorResponse = err?.response?.data;
        this.incrementFailedLoginAttempts();
        this.setErrorMessage(errorResponse?.error_description || 'Unable to authenticate');
      } else {
        this.setErrorMessage('Unable to authenticate');
      }
      return false;
    }

    if (!response) {
      this.setRequestInFlight(false);
      return false;
    }

    let authorizationResponse = null;
    try {
      authorizationResponse = await authorize(response.access_token);
    } catch (err) {
      this.setRequestInFlight(false);
      this.incrementFailedLoginAttempts();
      this.setErrorMessage(err.toString());

      return false;
    }

    if (!authorizationResponse) {
      this.setRequestInFlight(false);
      return false;
    }

    runInAction(() => {
      this.setAccessToken(authorizationResponse.portal_token);
      this.failedLoginAttempts = 0;
      this.warningMessage = null;
      this.errorMessage = null;
    });

    return true;
  }

  setRequestInFlight(value) {
    this.requestInFlight = value;
  }

  setErrorMessage(message) {
    this.errorMessage = message;
  }

  incrementFailedLoginAttempts() {
    this.failedLoginAttempts = this.failedLoginAttempts + 1;
    if (this.failedLoginAttempts > 2) {
      this.warningMessage =
        'You have entered an incorrect password more than 2 times. Please wait 15 minutes before logging in to avoid being blocked';
    } else {
      this.warningMessage = null;
    }
  }

  setAccessToken(token) {
    this.accessToken = token;
    localStorage.setItem(AUTH_LOCALSTORAGE_CACHE_KEY, token);
    this.updateInformationFromToken()

  }

  clearAccessToken() {
    this.accessToken = null;
    this.fullName = null;
    localStorage.removeItem(AUTH_LOCALSTORAGE_CACHE_KEY);
  }

  updateInformationFromToken() {
    if (this.accessToken) {
      const decodedAccessToken = jwtDecode(this.accessToken);
      this.fullName = decodedAccessToken.full_name;
    } else {
      this.fullName = null;
    }
  }

  loadAccessToken() {
    this.accessToken = localStorage.getItem(AUTH_LOCALSTORAGE_CACHE_KEY);
    this.updateInformationFromToken()
  }

  logout() {
    this.clearAccessToken();
    this.reset();
    router
      .navigate({
        to: '/login',
      })
      .catch(console.error);
  }

  async resetPassword(email) {
    let realm = 'Username-Password-Authentication';
    this.setRequestInFlight(true);
    let response= null;
    this.errorMessage = null;
    try {
      response = await resetPassword(email, {
        domain: AUTH_DOMAIN,
        client_id: AUTH_CLIENT_ID,
        connection: realm,
      });
    } catch (err) {
      this.setRequestInFlight(false);

      if (err instanceof AxiosError) {
        const errorResponse = err?.response?.data;
        this.setErrorMessage(errorResponse?.error_description || 'Unable to authenticate');
      } else {
        this.setErrorMessage('Unable to authenticate');
      }
      return false;
    }

    if (!response) {
      this.setRequestInFlight(false);
      return false;
    }
    return true;
  }
  setupAutoLogout() {
    if (!this.accessToken) return;
    const access_token = jwtDecode(this.accessToken);

    const autoLogoutInMs = access_token.exp * 1000 - Date.now();

    this.logoutTimer = setTimeout(
      () => {
        this.logout();
      },
      Math.max(0, autoLogoutInMs),
    );

    console.debug(
      `[Auth] Auto Logout has been set [in ${Math.floor(autoLogoutInMs / 1000)} seconds]`,
    );
  }
}

export default AuthStore;