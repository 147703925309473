import { createFileRoute, redirect } from '@tanstack/react-router';
import rootStore from "../stores";

export const Route = createFileRoute('/')({
  beforeLoad: async ({ location }) => {
    if (!rootStore.authStore.accessToken) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    } else {
      throw redirect({
        to: '/verify',
      });
    }
  },
});
