import makeStyles from '@mui/styles/makeStyles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const VerificationBackDrop = ({open, text=null}) => {
    const classes = useStyles();
    return (
        <Backdrop
              open={open}
            >
                <div className={classes.backDrop}>
                    <CircularProgress color="inherit" />
                    {
                        text ?
                            <div>{text}</div>
                        :
                            null
                    }

                </div>

            </Backdrop>
    )
}


const useStyles = makeStyles((theme) => ({
    backDrop: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        color: '#000d1a',
        justifyContent: 'center',
        alignItems: 'center',

    }
}));

export default VerificationBackDrop;
